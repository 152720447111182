
































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ButtonConfirm',
  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },
    message: {
      type: String,
      default: 'This action cannot be undone',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmLabel: {
      type: String,
      default: 'Delete',
    },
    color: {
      type: String,
      default: 'error',
    },
    text: {
      type: String,
      required:false,
    },
    icon: {
      type: String,
      default: 'clear',
    },
    confirmCondition: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    warnIcon: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonDialog: false,
    }
  },
  methods: {
    confirm() {
      this.$emit('confirmed')
      this.buttonDialog = false
    },
  },
});
